import classNames from 'classnames'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { NewsCategories } from '../hooks/newscategories'
import { usePageContext } from '../utils/PageContext'
import { NewsCard } from './NewsCard'
import { Subhead } from './Text'


export const News = ({ slice, items }) => {
    const { lang } = usePageContext();
    const filters = NewsCategories().filter(i => i.node.lang === lang).sort(i => i.node.data.primary ? -1 : 1)

    const isOverviewPage = slice.slice_label === 'overview'
    const classes = classNames(
        {
            'o-section--beige': !isOverviewPage
        }
    )

    return <section className={`o-section c-pillar ${classes}`}>
        <div className={`container`}>
            {slice.primary?.subhead && (
                <Subhead content={slice.primary.subhead}>
                    {isOverviewPage && (
                        <ul className={`o-filter`}>{filters.map((item, i) =>
                            <li key={`filter-${i}`} className={`o-filter__item`}><Link to={item.node.url} className={`o-filter__link`} activeClassName={`o-filter__link--active`}>{item.node.data.label || item.node.data.title}</Link></li>
                        )}
                        </ul>
                    )}
                </Subhead>
            )}
            <div className={`grid c-grid`}>
                {items && items.map((item, i) =>
                    <NewsCard key={`news-${i}`} url={item.node.url} data={item.node.data}></NewsCard>
                )}
            </div>
        </div>
    </section>
}

export default News

export const query = graphql`
query {
    allPrismicNewscategory {
        edges {
          node {
            lang
            url
            data {
              title
            }
            uid
          }
        }
      }
    
}

  fragment NewsSlice on PrismicPageDataBodyNews {
    primary {
        limit
        subhead {
            raw
          }
      }
      items {
        filters {
          url
        }
      }
      slice_type
      slice_label
  }
`