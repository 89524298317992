import { useStaticQuery, graphql } from "gatsby"

export const NewsCategories = () => {
  const {categories} = useStaticQuery(graphql`
  query NewsCategorieQuery {
    categories: allPrismicNewscategory {
        edges {
          node {
            lang
            url
            data {
                label
                title
                primary
            }
            uid
          }
        }
      }
  }
  `)
  return categories.edges;
}
